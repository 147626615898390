import React, { FormEvent, MouseEvent, useCallback, useState } from 'react';

import classes from './searchArea.module.scss';

import { Items } from '../types';
import { SearchInput } from './SearchInput';

interface ItemsProps {
    ID: number
    oldAnsver: string
    items: Items
    handleDelete: (event: MouseEvent) => void
    handleEditSearch: (event: FormEvent<HTMLFormElement>, ansver: string, ansverOld: string, ID: number) => void
}

export const FindedItem: React.FC<ItemsProps> = ({
    ID,
    oldAnsver,
    items,
    handleDelete,
    handleEditSearch
}) => {

    const [showEdit, setShowEdit] = useState(false);
    
    const handleEdit = useCallback((event: MouseEvent) => {
        !showEdit ? setShowEdit(true) : setShowEdit(false);
    }, [showEdit]);

    const handleSubmit = useCallback((event: FormEvent<HTMLFormElement>, ansver: string) => {
        handleEditSearch(event, ansver, oldAnsver, ID);
        setShowEdit(false);
    }, [ID, handleEditSearch, oldAnsver])

    return (
        items && <div className={classes.result}>
            {!showEdit ? <div className={classes.result__items}>
                <h6>
                    {oldAnsver}
                </h6>
                <a 
                    href={items.itemSummaries && items.itemSummaries[0].itemWebUrl} 
                    target='_blank'
                    rel='noreferrer'
                >
                    { items.itemSummaries ? items.itemSummaries[0].title : `not found ${oldAnsver}` }
                </a>
            </div>
            :
            <SearchInput handleSubmit={handleSubmit} value={oldAnsver}/>
            }
            <div className={classes.result__buttons}>
                <p>
                    {items.itemSummaries ? 
                        items.itemSummaries[0].price ? 
                            `${items.itemSummaries[0].price.value} ${items.itemSummaries[0].price.currency}` 
                            :
                            `${items.itemSummaries[0].currentBidPrice.value} ${items.itemSummaries[0].currentBidPrice.currency}`
                        :
                        '000.0 USD'
                    }
                </p>
                <button 
                    className={classes.result__buttons_button}
                    id={'' + ID}
                    onClick={handleEdit}
                >E</button>
                <button 
                    className={classes.result__buttons_button}
                    id={'' + ID}
                    onClick={handleDelete}
                >X</button>
            </div>
        </div>
    )
}