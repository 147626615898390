import { ChangeEvent, FormEvent, useCallback, useState } from 'react';

import classes from './searchArea.module.scss';

interface InputProps {
    handleSubmit: (event: FormEvent<HTMLFormElement>, ansver: string) => void,
    value?: string
}

export const SearchInput: React.FC<InputProps> = ({
    handleSubmit,
    value = ''
}) => {

    const [searchAnsver, setSearchAnsver] = useState('');

    const handleChangeSearch = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearchAnsver(event.target.value);
    }, []);

    const handleSubmitSearch = useCallback((event: FormEvent<HTMLFormElement>) => {
        handleSubmit(event, searchAnsver);
        setSearchAnsver('');
    }, [handleSubmit, searchAnsver]);

    return (
        <form onSubmit={handleSubmitSearch} className={classes.form}>
            <input type="text" className={classes.input} onChange={handleChangeSearch} defaultValue={value}/>
            <button type='submit' className={classes.button}>Search</button>
        </form>
    )
}