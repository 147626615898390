import { createAction, createAsyncAction } from "typesafe-actions";

export const setMail = createAsyncAction(
    'feature/search/SET_EMAIL_REQUEST',
    'feature/search/SET_EMAIL_SUCCESS',
    'feature/search/SET_EMAIL_FAILURE',
)<string, string, string>();

export const initMail = createAction(
    'feature/search/GET_EMAIL_REQUEST'
)<void>();